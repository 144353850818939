html,
body,
#root {
  height: 100%;
}

.container {
  min-height: 100%;
}

.header-sigep,
.footer {
  background-color: #54b6e5;
}

.card-destinatario,
.card-prelistagem,
.card-cartao-postal {
  width: 100%;
  margin: 30px 0;
}

.card-cartao-postal {
  width: 100%;
  margin: 30px 0;
}

.card-header {
  display: flex;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.btn-top {
  margin: 0 5px;
}

.btn-considerar,
.btn-adicionar {
  margin-top: 30px;
}

.user-name {
  color: #ffffff !important;
  text-decoration: none !important;
}

.btn-sair {
  color: rgba(255, 255, 255, 0.7);
  margin-left: 20px;
}

.btn-sair:hover {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 20px;
  text-decoration: none;
}

.titulo {
  display: flex;
  width: 100%;
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  background-color: rgba(0, 0, 0, 0.03);
}
.titulo .titulo-tabela {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
}

.btn-editar {
  color: #ffc107;
  font-size: 16px;
}
.tbl-id {
  width: 5%;
}
.destinatario-nome {
  width: 34%;
}
.destinatario-endereco {
  width: 20%;
}
.destinatario-cidade {
  width: 15%;
}
.destinatario-cep {
  width: 10%;
}
.destinatario-situacao {
  width: 10%;
}
.destinatario-acao {
  width: 5%;
}
.tbl-endereco-endereco {
  width: 43%;
}
.tbl-endereco-bairro {
  width: 25%;
}
.tbl-endereco-cep {
  width: 20%;
}
.tbl-endereco-acao {
  width: 5%;
}

.btnSave {
  margin-right: 5px;
}

.footer {
  background-color: #54b6e5;
  padding: 20px 0 5px 0;
}

.table-bordered th,
.table-bordered td {
  padding-bottom: 0.2rem !important;
}

.tabs-destinatario {
  justify-content: flex-end;
  position: relative;
  top: -63px;
}

.informacoes-gerais {
  width: 100%;
}

.btn-add-modal {
  height: 40px;
  margin-top: 30px;
}

.form-check {
  margin-bottom: 3px;
}

.manter-postagem .card {
  margin-bottom: 25px;
}

.k-pdf-export .print-container {
  display: block;
  margin: 40px;
  font-family: "DejaVu Sans", "Arial", sans-serif;
}

.k-pdf-export .quebrapagina {
  page-break-before: always;
}

.k-pdf-export body {
  text-align: left;
  font-family: "DejaVu Sans", "Arial", sans-serif;
}

.k-pdf-export .header-declaracao {
  margin: 0 auto;
  border: 2px solid black;
}

.k-pdf-export .header-declaracao .title {
  text-align: center;
  margin: 0;
  padding: 10px;
}

.k-pdf-export .container-endereco {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.k-pdf-export .remetente,
.destinatario {
  width: 48%;
  border: 2px solid black;
  border-top: none;
}

.k-pdf-export .card-body .info-declaracao {
  border: 2px solid black;
  padding: 0 20px;
  font-size: 12px;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.k-pdf-export .title h3 {
  text-align: center;
  margin: 0;
  padding: 10px;
}

.k-pdf-export .card-cidade-uf,
.card-cep-cnpj {
  display: flex;
}

.k-pdf-export .card-cidade-uf .card-cidade,
.card-cep-cnpj .card-cep {
  flex-grow: 2;
}

.k-pdf-export .card-cidade-uf .card-uf,
.card-cep-cnpj .card-cnpj {
  flex-grow: 1;
  border-left: 2px solid;
  padding-left: 15px;
}

.k-pdf-export p {
  margin: 0;
  padding: 10px 0;
}

.k-pdf-export .body {
  border: 2px solid black;
  border-top: none;
  padding: 20px;
  text-align: left;
}

.k-pdf-export table {
  font-family: "DejaVu Sans", "Arial", sans-serif;
  width: 100%;
}

.k-pdf-export h4 {
  border: 2px solid black;
  text-align: center;
  margin-bottom: 0;
  padding: 10px;
}

.k-pdf-export table,
th,
td {
  text-align: center;

  font-family: "DejaVu Sans", "Arial", sans-serif;
  border: 1px solid black;
  border-collapse: collapse;
}
.k-pdf-export table thead {
  background-color: rgb(215, 215, 215);
}

.k-pdf-export table tr th {
  padding: 5px;
}

.k-pdf-export table tr td {
  padding: 5px;
  text-align: center;
}

.k-pdf-export table tr .total {
  background-color: rgb(215, 215, 215);
  text-align: right;
  font-weight: 600;
}

.k-pdf-export .declaracao .body {
  border: 2px solid black;
  border-top: none;
  padding: 20px;
  text-align: left;
}

.k-pdf-export .data-assinatura {
  display: flex;
}

.k-pdf-export .data-assinatura .data {
  display: flex;
  flex-grow: 2;
}

.k-pdf-export .data-assinatura .data p {
  padding: 40px 0;
  margin: 0 1px;
}

.k-pdf-export .assinatura {
  flex-grow: 1;
  background-color: rgb(215, 215, 215);
  padding: 10px 50px;
}

.k-pdf-export .assinatura p {
  margin: 50px 10px 10px 10px;
  border-top: 1px solid black;
}

.k-pdf-export footer {
  padding: 10px 20px;
  border: 2px solid black;
  margin-top: 3px;
}

.k-pdf-export footer p {
  padding: 0;
}

@font-face {
  font-family: "DejaVu Sans";
  src: url("../fonts/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans Bold";
  font-weight: bold;
  src: url("../fonts/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("../fonts/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/DejaVuSans-ExtraLight.ttf") format("truetype");
}
